import moment from 'moment';
import { ITrainingItemDate } from '../interfaces/Calendar';

export const secondsToHMS = (secs: number) => {
    const sec_num = parseInt(String(secs), 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
};

export const parseHourAndMinutes = (secs: number) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs - hours * 3600) / 60);
    const minutesStr = String(Math.floor((secs - hours * 3600) / 60));

    let res = '';
    if (hours > 0) {
        res += `${hours}h `;
    }
    if (minutes > 0) {
        res += `${minutesStr} min.`;
    } else {
        res += `0 min.`;
    }

    return res;
};

export const closestDateFrom = (eventDates: ITrainingItemDate[]): ITrainingItemDate => {
    return eventDates.find((eventDate) => {
        const diff = moment(eventDate.date.from).diff(moment(), 'minutes');

        return diff >= 0;
    });
};

export const formatNotificationDate = (date: Date): string => {
    const notificationDate = moment(date);
    const today = moment();
    const dayDiffNumber = notificationDate.diff(today, 'days');

    console.log('AA', Math.abs(Math.floor(dayDiffNumber / 7)));
    if (dayDiffNumber === 0) return 'dnes';
    if (dayDiffNumber === -1) return 'včera';
    if (dayDiffNumber < -1 && dayDiffNumber > -7) return `pred ${Math.abs(Math.floor(dayDiffNumber))} dňami`;
    if (dayDiffNumber <= -7 && dayDiffNumber > -14) return 'pred týždňom';
    if (dayDiffNumber <= -14) return `pred ${Math.abs(Math.floor(dayDiffNumber / 7))} týždňami`;
};
