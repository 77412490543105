import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { getMaxPageWidth } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Section = styled.div<{ isStaticBanner: boolean }>`
    border-radius: ${rem(16)};
    border: 1px solid ${({ theme }) => theme.colors.border};
    max-width: ${(prop) => (prop.isStaticBanner ? rem(320) : rem(900))};
    margin: 0 auto;
    padding: ${rem(30)} ${rem(8)};
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
        padding: ${(prop) => (prop.isStaticBanner ? rem(20) : rem(30))};
    }
`;

export const Description = styled.span<{ isStaticBanner?: boolean }>`
    display: block;
    font-size: ${(prop) => (prop.isStaticBanner ? prop.theme.fontSizes.textMedium : prop.theme.fontSizes.h5)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    padding-bottom: ${rem(20)};
    line-height: ${rem(28)};
`;

export const InputSection = styled.div`
    position: relative;
    margin-bottom: ${rem(25)};
    display: flex;
    flex-direction: row;
    height: ${rem(45)};
    gap: ${rem(15)};
`;

export const Input = styled.input`
    width: 100%;
    height: ${rem(45)};
    padding: ${rem(10)};
    border-radius: ${rem(4)};
    background: none;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.secondaryGrey};
    padding-right: ${rem(60)};
    font-size: ${({ theme }) => theme.fontSizes.textMedium};

    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
        opacity: 0.4;
    }

    &.error {
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;

export const ErrorMessage = styled.span`
    margin: 0 0 ${rem(10)} 0;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    color: ${({ theme }) => theme.colors.danger};
    text-align: left;
    position: absolute;
    bottom: ${rem(-25)};
`;

export const SuccessMessage = styled.span`
    margin: 0 0 ${rem(10)} 0;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
    position: absolute;
    bottom: ${rem(-30)};
`;

export const Social = styled.div`
    text-align: center;
`;

export const Link = styled.a`
    color: ${({ theme }) => theme.colors.black};
`;

export const Icon = styled(IconTemplate)<{ isStaticBanner?: boolean }>`
    background: ${({ theme }) => theme.colors.white};
    margin: ${(prop) => (prop.isStaticBanner ? `${rem(10)} ${rem(4)}` : rem(10))};
    border-radius: 50%;
    padding: ${rem(5)} ${rem(8)};
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
        border: 2px solid ${({ theme }) => theme.colors.black};
    }
`;
